/**
 * This method generates url hash from given string
 * @param value
 * @returns string
 * @see ./app-cms/packages/rawlplug_base/Resources/Public/JavaScript/Plugins/TableOfContents/plugin.js#L49
 */

function generateUrlHash(value: string): string {
  return value
    .trim()
    .toLocaleLowerCase()
    .replace(/ł/g, 'l')
    .replace(/_/g, '-')
    .split(' ')
    .map((word: string) => word.normalize('NFKD').replace(/\W/g, ''))
    .join('-')
    .replace(/^-+/g, '')
    .replace(/-+$/g, '')
    .replace(/-+/g, '-')
}

export default generateUrlHash
